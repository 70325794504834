import React from 'react'
import './style.css'

export default function index() {
    return (
        <div id="DevCard" className='card border-0'>
            <p id="developmentTime" className='mt-4'>Project Duration</p>
            <div className='text-center' style={{ padding: "4rem" }}>
                <p id='SketchedWord'>
                    Months
                </p>
                <p id="DisplayedWord">
                    Weeks </p>
            </div>
        </div>
    )
}
