import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './style.css'
import Logo from '../../img/Logo.png'

export default function Navbar({ moveToAbout, moveToContact, moveToProjects, moveToFAQ }) {

    const [navbarBlur, setNavbarBlur] = useState(false);

    // check the scroll pixel of navbar to apply the blur effect
    const changeNavbarBackground = () => {
        // console.log(window.scrollY)
        if (window.scrollY > 52) {
            setNavbarBlur(true)
        }
        else {
            setNavbarBlur(false)
        }
    }

    // event lister for scrolling
    window.addEventListener('scroll', changeNavbarBackground)
    return (
        <>
            <nav className={navbarBlur ? "navbar navbar-expand-lg navbar-light navbar-section blurred" : "navbar navbar-expand-lg navbar-light navbar-section"}>
                <div className="container-fluid">
                    <Link to="/"><img id="logo" alt="FN logo" src={Logo} style={{ top: "2px", left: "2px", width: "40px", height: "40px" }} /></Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarToggleExternalContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
                        <ul className="navbar-nav" >
                            <li className="nav-item">
                                <a id="btnAbout" className="nav-link active " aria-current="page" aria-label='About Me' href="#about" onClick={moveToAbout}>About</a>
                            </li>
                            <li className="nav-item">
                                <a id="btnProject" className="nav-link active " aria-current="page" aria-label='Previous Projects' href="#recentprojects" onClick={moveToProjects}>Projects</a>
                            </li>
                            <li className="nav-item">
                                <a id="btnFAQ" className="nav-link active " aria-current="page" href="#faq" aria-label='Frequently Asked Questions' onClick={moveToFAQ}>FAQ</a>
                            </li>
                            <li className="nav-item">
                                <a id="btnContact" className="nav-link active " aria-current="page" href="#contact" aria-label='Contact Me' onClick={moveToContact}>Contact</a>
                            </li>
                        </ul>

                    </div>
                </div>
            </nav>
        </>
    )
}