import React, { useState } from 'react'
import './style.css'


export default function TwoSideCard({ title, desc, pic, detail, alt }) {

    const [showMore, setShowMore] = useState(false);
    const [hidePicture, setHidePicture] = useState("");
    const [displayStatus, setDisplayStatus] = useState("none")
    const [buttonInfo, setButtonInfo] = useState("Show More")
    const [buttonColor, setButtonColor] = useState("#3E4852")

    function handleChange(event) {
        event.preventDefault();
        if (showMore === false) {
            setShowMore(true);
            setButtonInfo("Show Less")
            setButtonColor("white")
            setHidePicture("none")
            setDisplayStatus("")
        }
        else {
            setShowMore(false)
            setButtonInfo("Show More")
            setButtonColor("#3E4852")
            setDisplayStatus("none")
            setHidePicture("")
        }
        // changeStatus()
    }
    return (
        <div className='container'>
            <div id="proCard" className='card mt-2' style={{ backgroundColor: showMore ? "#4A4647" : "white", height: "33rem", position: "relative" }} >
                <div className='row' style={{ display: `${hidePicture}` }} >
                    <div className='col'>
                        <div className='container'>
                            {/* <h2 className='mt-5' id="projectTitle">{title}</h2> */}
                            <img id="projectImage" src={pic} alt={alt} />
                        </div>
                    </div>
                </div>
                <div className='row' style={{ display: `${displayStatus}` }} >
                    <div className='col'>
                        <div className='container'>
                            <p id='projectInfo'>
                                {detail}
                            </p>
                        </div>
                    </div>
                </div>
                <div className='row' style={{ position: "absolute", bottom: "1rem", right: "1.5rem" }} >
                    <div className='col' >
                        <div style={{ textAlign: "right" }}>
                            <button id='btnProjects' type="button" aria-label={`${buttonInfo}`} class="btn" style={{ color: `${buttonColor}` }} onClick={handleChange}> {buttonInfo}</button>
                        </div>
                    </div>

                </div>
            </div>
        </div >

    )
}
