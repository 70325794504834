import React from 'react'
import ProjectCard from '../ProjectCard'
import GoldieMohr from '../../img/GoldieMohr.png'
import Sinai from '../../img/Sinai.png'
import RedRadius from "../../img/RedRadius.png"
import './style.css'

export default function index({ refName }) {
    return (
        <div className='container' ref={refName}>
            <p id="previousProject" className="">Previous Projects</p>
            <div className='row'>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12' >
                {/* <Project image={RedRadius} name="RedRadius Immigration" deployed="https://redradius.ca" repository="" description="This project aims to develop a new, highly improved website for RedRadius Immigration Consultancy. Built as a single-page application using React.js, the website offers a comprehensive range of immigration services and enables clients to book consultation meetings easily through integrated external platforms. This enhancement provides a seamless, user-friendly experience, making it simple for clients to access the needed services." /> */}

                     <ProjectCard title={"RedRadius Immigration"} pic={RedRadius} alt={"Homepage of a website called RedRadius Immigration that provides immigration services"} detail={"This project aims to develop a new, highly improved website for RedRadius Immigration Consultancy. Built as a single-page application using React.js, the website offers a comprehensive range of immigration services and enables clients to book consultation meetings easily through integrated external platforms. This enhancement provides a seamless, user-friendly experience, making it simple for clients to access the needed services."} />

                    {/* <ProjectCard title={"Goldie Mohr Ltd"} pic={GoldieMohr} alt={"Homepage of a website that consists of two blue trucks and two persons standing beside them"} detail={"The aim of this project was to build a modern website for a heavy civil construction company that specializes in site preparation, custom trucking, and float service. The new site will feature user-friendly navigation with separate pages for home, services & projects, products, about us, careers, and contact us. Additionally, registered employees will enjoy access to exclusive content via a login feature."} /> */}
                </div>
                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12' >
                    <ProjectCard title={"Sinai"} pic={Sinai} alt={"Homepage of a website that consists of a young girl smiling and two women and two men packing food bags"} detail={"The goal was to develop an enhanced website using React.js for a non-profit organization that promotes community empowerment. The new website provides easy-to-use navigation with separate pages for home, about us, programs, events, volunteer opportunities, and donations. It also features engaging visuals to inspire visitors to support the organization's mission."} />

                </div>
            </div>

        </div>
    )
}
