import React from 'react'
import './style.css'

export default function index({ imgSource, toolName }) {
    return (

        <div id='toolCard' className='card border-0'>
            <div className='text-center pt-4 pb-4'>
                <div>
                    <img id="" className="" src={imgSource} alt="" style={{ maxWidth: "40px", height: "40px" }} />
                    <p id="toolText">{toolName}</p>
                </div>
            </div>
        </div>
    )
}


