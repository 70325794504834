import React from 'react'
import './style.css'
import Card from '../Card'
import Intro from '../Intro'

export default function index({ refName }) {
    return (

        <div className="about-section" ref={refName}>
            <div div className="container p-5" >
                <div className='row mt-3' >
                    <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12" >
                        <Intro />
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12" >
                        <div className='row ' id="split-row-four-cards">
                            <div className='col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                <Card firstColor="#F5B50C" secondColor="#DE6355" cardTitle="10" cardSubtitle="+" cardText="Web Technology" />
                            </div>
                            <div className='col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                <Card firstColor="#58D0D9" secondColor="#3278C6" cardTitle="3" cardSubtitle="Years" cardText="Experience" />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                <Card firstColor="#A6FF06" secondColor="#008000" cardTitle="15" cardSubtitle="+" cardText="Projects" />
                            </div>
                            <div className='col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                <Card firstColor="#F59D7C" secondColor="#C94790" cardTitle="100" cardSubtitle="%" cardText="Commitment" />
                            </div>
                        </div>
                    </div >
                </div >
            </div>
        </div>
    )
}
