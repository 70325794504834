import React from 'react'
import './style.css'
export default function index({ firstColor, secondColor, cardTitle, cardSubtitle, cardText }) {
    return (
        <div id='fact-card' className='card  border-0'>
            <div className='text-center pt-4 pb-4'>
                <div>
                    <span id="fact-card-title" style={{ backgroundImage: `linear-gradient(${firstColor}, ${secondColor})` }}>{cardTitle}</span><span id="fact-card-subtitle" style={{ backgroundImage: `linear-gradient(${firstColor}, ${secondColor})` }}>{cardSubtitle}</span>
                    <p id="fact-card-text">{cardText}</p>
                </div>
            </div>
        </div>
    )
}
