import React from 'react'
import MobileResponsive from '../MobileResponsive'
import Info from '../Info'

export default function index() {
    return (

        <div style={{ backgroundColor: "#F7F7F7" }}>
            <div className="container p-5">
                <div className="row mt-3">
                    <div className="col-xxl-5 col-xl-5 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-5">
                        <Info />
                    </div>
                    <div className="col-xxl-7 col-xl-7 col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-5">
                        <MobileResponsive />
                    </div>



                </div>



            </div>
        </div>

    )
}
