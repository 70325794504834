import './App.css';
import React, { useRef } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Wrapper from './components/Wrapper';
import Footer from './components/Footer';
import Navbar from './components/Navbar'
import Home from './components/Home'
import About from './components/About'
import Projects from './components/Projects'
import Contact from './components/Contact'
import InfoRow from './components/InfoRow'
import FAQ from './components/FAQ'
import MidSection from './components/MidSection'
import ToolSection from './components/ToolSection'
import ProjectSection from './components/ProjectSection'
// import Hero from './components/Hero'

function App() {

  const contactRef = useRef()
  const projectsRef = useRef()
  const aboutRef = useRef()
  const faqRef = useRef()

  function moveToContact() {
    contactRef.current.scrollIntoView()
  }
  function moveToAbout() {
    aboutRef.current.scrollIntoView()
  }
  function moveToProjects() {
    projectsRef.current.scrollIntoView()
  }
  function moveToFAQ() {
    faqRef.current.scrollIntoView()
  }

  return (
    <div>
      <Router>

        <Wrapper>
          <Navbar moveToAbout={moveToAbout} moveToProjects={moveToProjects} moveToContact={moveToContact} moveToFAQ={moveToFAQ} />
          <Home moveTo={moveToContact} />
          <About refName={aboutRef} />
          <MidSection />
          <ToolSection />
          <InfoRow />
          <ProjectSection refName={projectsRef} />
          {/* <Projects refName={projectsRef} /> */}
          <FAQ refName={faqRef} />
          <Contact refName={contactRef} />
        </Wrapper>
        <Footer />
      </Router>
    </div>

  );
}

export default App;
