import "./style.css"
import React, { useState } from "react"
import emailjs from 'emailjs-com';
import Modal from "../Modal"


export default function Contact({ refName }) {

    const [senderName, setName] = useState("")
    const [senderEmail, setEmail] = useState("")
    const [senderMessage, setMessage] = useState("")
    const [show, setShow] = useState(false)

    function showModal() {
        setShow(true)
    }

    function hideModal() {
        setShow(false)
    }

    function handleChange(event) {

        event.preventDefault()
        const id = event.target.id
        const value = event.target.value

        if (id === "senderName") {
            setName(value)
        }
        else if (id === "senderEmail") {
            setEmail(value)
        }
        else if (id === "senderMessage") {
            setMessage(value)
        }

    }

    function sendEmail(event) {
        event.preventDefault();

        emailjs
            .sendForm("service_7wfzojs", "contact_form", event.target, "user_nOyVexTohkJUOhOVmUL5g"
            )
            .then(function (response) {
                console.log('SUCCESS!', response.status, response.text);
            }, function (error) {
                console.log('FAILED...', error);
            });

        showModal()

        setName("")

        setEmail("")

        setMessage("")

    }


    return (
        <>
            <div style={{ backgroundColor: "#f7f7f7" }}>
                <div className="container" ref={refName}>
                    <Modal show={show} handleClose={hideModal}>
                        <p>Your message has been sent successfully </p>
                    </Modal>
                    <p id="contactMe" className="fluid-gradient-heading-contact" >Interested in working with me or have a question? </p>
                    {/* <div id="line" ></div> */}
                    <form onSubmit={sendEmail}>
                        <input name="contact_number" type="hidden" value={parseInt(Math.random() * 10000)} />
                        <div className="row">
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <div className="mb-3">
                                    <label for="senderName" className="form-label" style={{ color: "#3E4852" }}>Name</label>
                                    <input type="text" name="user_name" value={senderName} style={{ borderRadius: "10px", backgroundColor: "transparent" }} className="form-control mt-3" id="senderName" onChange={handleChange} placeholder="Your name" required />
                                </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <div className="mb-3">
                                    <label for="senderEmail" className="form-label" style={{ color: "#3E4852" }}>Email address</label>
                                    <input type="email" name="user_email" value={senderEmail} style={{ borderRadius: "10px", backgroundColor: "transparent" }} className="form-control mt-3" id="senderEmail" onChange={handleChange} placeholder="Enter your email address" required />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="mb-3">
                                <label for="senderMessage" className="form-label" style={{ color: "#3E4852" }}>Message</label>
                                <textarea className="form-control mt-3" name="message" value={senderMessage} style={{ borderRadius: "10px", backgroundColor: "transparent" }} id="senderMessage" rows="6" onChange={handleChange} placeholder="Enter your message here" required ></textarea>
                            </div>
                            <div className="mb-3" style={{ textAlign: "center" }}>
                                <button type="submit" value="Send" name="submit" aria-label="Send Form" className="btn" id="btnSend" onclick={sendEmail}>Send</button>
                            </div>
                            <div className="mb-3" style={{ color: "grey" }}>
                                <p>You hate forms? <a style={{ textDecoration: "none", color: "#016edb" }} target="_blank" rel="noreferrer" href="mailto:faisalskhalifa@gmail.com">faisalskhalifa@gmail.com</a> or call <a href="tel:+16473095646" style={{ textDecoration: "none", color: "#016edb" }}> +1 (647) 309-5646 </a></p>
                            </div>
                        </div>
                    </form>

                </div>

            </div>
        </>
    )
}