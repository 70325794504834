import React from 'react'
import './style.css'
import DevDuration from '../DevDuration'
import ToolCard from '../ToolCard'
import JavaScriptIcon from '../../img/icons/JavaScriptIcon.svg'
import Html5Icon from '../../img/icons/Html5Icon.png'
import CssIcon from '../../img/icons/CssIcon.webp'
import BootStrapIcon from '../../img/icons/BootStrapIcon.webp'
import NodeJsIcon from '../../img/icons/NodeJsIcon.webp'
import SassIcon from '../../img/icons/SassIcon.svg'
import PhotoshopIcon from '../../img/icons/PhotoshopIcon.svg'
import ReactJsIcon from '../../img/icons/ReactJsIcon.svg'
// import InvisionIcon from '../../img/icons/InvisionIcon.svg'
// import IllustratorIcon from '../../img/icons/IllustratorIcon.svg'
// import GitIcon from '../../img/icons/GitIcon.svg'


export default function index() {
    return (
        <div id='toolSection'>
            <div className='container p-5'>
                <div className='row '>
                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-5'>
                        <DevDuration />
                    </div>
                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-5'>
                        <div className='row split-row'>
                            <div className='col'>
                                <ToolCard toolName="HTML5" imgSource={Html5Icon} />
                            </div>
                            <div className='col'>
                                <ToolCard toolName="CSS3" imgSource={CssIcon} />
                            </div>
                            <div className='col'>
                                <ToolCard toolName="JavaScript" imgSource={JavaScriptIcon} />
                            </div>
                            {/* <div className='col'>
                                <ToolCard toolName="InVision" imgSource={InvisionIcon} />
                            </div> */}
                        </div>
                        <div className='row split-row'>
                            <div className='col'>
                                <ToolCard toolName="Bootstrap" imgSource={BootStrapIcon} />
                            </div>
                            <div className='col'>
                                <ToolCard toolName="Sketch" imgSource="https://static10.lottiefiles.com/images/workflow-homepage/logos/svg/logo-sketch.svg" />
                            </div>
                            <div className='col'>
                                <ToolCard toolName="React" imgSource={ReactJsIcon} />
                            </div>
                            {/* <div className='col'>
                                <ToolCard toolName="Illustrator" imgSource={IllustratorIcon} />
                            </div> */}
                        </div>
                        <div className='row'>
                            <div className='col'>
                                <ToolCard toolName="SASS" imgSource={SassIcon} />
                            </div>
                            <div className='col'>
                                <ToolCard toolName="Node.js" imgSource={NodeJsIcon} />
                            </div>
                            <div className='col'>
                                <ToolCard toolName="Photoshop" imgSource={PhotoshopIcon} />
                            </div>
                            {/* <div className='col'>
                                <ToolCard toolName="Git" imgSource={GitIcon} />
                            </div> */}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
