import React from 'react'
import CanandaFlag from '../../../src/img/canada-flag.png'
import './style.css'

export default function index({ refName }) {
    return (
        <div id="container" ref={refName} >

            <div style={{ backgroundColor: "#f7f7f7" }}>
                <div className="container  ">
                    <p className="faq-title">Frequently asked questions</p>
                    <div class="accordion accordion-flush" id="accordionExample" >
                        <div className="row">
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 pb-5">
                                <div className="accordion-item" style={{ borderRadius: "1rem", color: "#3E4852" }}>
                                    <h2 className="accordion-header" id="headingOne" >
                                        <button className="accordion-button collapsed faq-question" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style={{ borderRadius: "1rem", zIndex: "0", border: "0px", color: "#3E4852" }}  >
                                            What services do you offer?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body pt-4" style={{ backgroundColor: "white", borderRadius: "1rem", color: "#3E4852" }}>
                                            My focus and expertise is Front-End Web Development. I do build website and single page applications using React and other technologies.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 pb-5">
                                <div className="accordion-item" style={{ borderRadius: "1rem" }}>
                                    <h2 className="accordion-header" id="headingTwo" >
                                        <button className="accordion-button collapsed faq-question" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo" style={{ borderRadius: "1rem", zIndex: "0", border: "0px", color: "#3E4852" }}  >
                                            Do you work internationally?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="accordion-body pt-4" style={{ backgroundColor: "white", borderRadius: "1rem", color: "#3E4852" }}>
                                            Yes. I am based in the city of Toronto in Canada <img src={CanandaFlag} alt="Canadian flag" width="22px" height="15px" /> but I work with clients from all over the world.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* second row */}

                        <div className="row">
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 pb-5">
                                <div className="accordion-item" style={{ borderRadius: "1rem" }}>
                                    <h2 className="accordion-header" id="headingThree" >
                                        <button className="accordion-button collapsed faq-question" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree" style={{ borderRadius: "1rem", zIndex: "0", border: "0px", color: "#3E4852" }}  >
                                            How do you accept payment?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div className="accordion-body pt-4" style={{ backgroundColor: "white", borderRadius: "1rem", color: "#3E4852" }}>
                                            I accept payment through various methods; PayPal, Wire Transfer through banks, and Interac e-transfer for clients from Canada.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 pb-5">
                                <div className="accordion-item" style={{ borderRadius: "1rem" }}>
                                    <h2 className="accordion-header" id="headingFour" >
                                        <button className="accordion-button collapsed faq-question" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour" style={{ borderRadius: "1rem", zIndex: "0", border: "0px", color: "#3E4852" }}  >
                                            How long does a project take?
                                        </button>
                                    </h2>
                                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                        <div className="accordion-body pt-4" style={{ backgroundColor: "white", borderRadius: "1rem", color: "#3E4852" }}>
                                            It depends on the requirements and complexity of a project. Usually it takes three weeks to two months.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div >
        </div>
    )
}
