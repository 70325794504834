import React from 'react'
import './style.css'
export default function index() {
    return (
        <div id="intro-card" className='card  border-0'>
            <p id='intro-card-title'>
                How I differentiate myself...
            </p>
            <p id="intro-card-text" >
                I specialize in transforming designs and wireframes into fully-functional and responsive websites utilizing the cutting-edge technology of React. My focus extends beyond technical capabilities to create inclusive and user-friendly websites that adhere to web accessibility best practices to ensure enjoyable user experience for all users, including those with disabilities.            </p>
        </div>

    )
}
