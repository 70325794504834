import React, { useState, useEffect } from 'react'
import './style.css'

export default function MidSection() {

    const wordsList = ["Inclusive Design", "Accessible Application", "Greater Visibility",
        "Elevated Experience", "Diverse Users", "Better Revenue"];
    const [sectionWord, setSectionWord] = useState(wordsList[1]);
    const [counter, setCounter] = useState(-1);

    useEffect(() => {
        // changeWord()
        let interval = setInterval(() => {
            setCounter((() => counter + 1))
            setSectionWord(() => wordsList[counter])
            if (counter === wordsList.length - 1) {
                setCounter(() => 0)
            }
        }, 2000);
        return () => clearInterval(interval)
    },);

    return (
        <div id='midSection'>

            <div className='container p-5 col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                <div id="MidSectionCard" className='card shadow-sm'>
                    <div className='mt-3 mb-3 text-center' style={{ color: "white" }}>
                        <p id="midSectionWord">{sectionWord}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
