import React from 'react'
import './style.css'

export default function index() {
    return (
        <div className="card border-0" id="design" >
            <p id="cleanCodeText" className="mt-5" style={{ marginBottom: "0px" }}> Clean Code</p >
            <div id="unorder-list" >
                <p><span className="unique-txt">&lt; ul &gt;</span></p>
                <p className="unique-list"><span className="unique-txt">&lt; li &gt;</span> Proper Document Structure <span className="unique-txt">&lt; /li &gt;</span></p>
                <p className="unique-list"><span className="unique-txt">&lt; li &gt;</span> Semantic HTML <span className="unique-txt">&lt; /li &gt;</span></p>
                <p className="unique-list"><span className="unique-txt">&lt; li &gt;</span> Standards-Compliant Markup <span className="unique-txt">&lt; /li &gt;</span></p>
                <p className="unique-list"><span className="unique-txt">&lt; li &gt;</span> Continually Refactoring Code <span className="unique-txt">&lt; /li &gt;</span></p>
                <p><span className="unique-txt">&lt; /ul &gt;</span></p>
            </div>
        </div>
    )
}
