import React from 'react'
import ResponsiveDesignGIFTwo from '../../img/ResponsiveDesignGIFTwo.gif'

import './style.css'

export default function index() {
    return (
        <div className="card border-0" id="mobile-design" >
            <p id="responsiveDesignPictureTitle" className="mt-5">Responsive Design</p>
            <img id="responsiveDesignPicture" src={ResponsiveDesignGIFTwo} alt="responsive design" style={{ maxWidth: "100%", maxHeight: "100%" }} />
        </div >
    )
}
